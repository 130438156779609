/*

? First, let's import the required components.

*/
import { styled } from "@mui/system";
import React from "react";

import {
	Autocomplete,
	CircularProgress,
	InputAdornment,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
/*

* Google Maps Imports

*/
import { Search } from "@mui/icons-material";
import NearMeIcon from "@mui/icons-material/NearMe";
import { useLoadScript } from "@react-google-maps/api";
import { useRouter } from "next/router";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../../../redux-magic/thunks";

/*

* Interface for location

*/

interface Suggestion {
	description: string;
	matched_substrings: Array<{ length: number; offset: number }>;
	place_id: string;
	reference: string;
	structured_formatting: {
		main_text: string;
		main_text_matched_substrings: Array<{ length: number; offset: number }>;
		secondary_text: string;
	};
	terms: Array<{ offset: number; value: string }>;
	types: Array<string>;
}

interface GeolocationCoordinates {
	latitude: number;
	longitude: number;
	altitude: number | null;
	accuracy: number;
	altitudeAccuracy: number | null;
	heading: number | null;
	speed: number | null;
}
interface GeolocationPosition {
	coords: GeolocationCoordinates;
}
interface GMRGCPlusCode {
	compound_code: string;
	global_code: string;
}
interface GMRGCResultsAddressComponent {
	long_name: string;
	short_name: string;
	types: Array<string>;
}
interface GMRGCResultsGeometryLocation {
	lat: number;
	lng: number;
}
interface GMRGCResultsGeometryBoundsAndGeometryViewport {
	northeast: GMRGCResultsGeometryLocation;
	southwest: GMRGCResultsGeometryLocation;
}
interface GMRGCResultsGeometry {
	bounds: GMRGCResultsGeometryBoundsAndGeometryViewport;
	location: GMRGCResultsGeometryLocation;
	location_type: string;
}
interface GMRGCResults {
	address_components: Array<GMRGCResultsAddressComponent>;
	formatted_address: string;
	geometry: GMRGCResultsGeometry;
	viewport: GMRGCResultsGeometryBoundsAndGeometryViewport;
	place_id: string;
	types: Array<string>;
}
interface GoogleMapsReverseGeocodingApiResponse {
	plus_code: GMRGCPlusCode;
	results: Array<GMRGCResults>;
	status: string;
}

/*

& Next, let's create the component.

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	padding: "0.25rem 0.6rem 0.25rem 0.6rem",
	border: `1px solid ${theme.palette.primary.main}`,
	backgroundColor: theme.palette.background.paper,
	// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	borderRadius: "8px",
}));

const OptionContainer = styled("li")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "space-between",
	width: "100%",

	borderRadius: "10px",
}));

const OptionText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "isPrimary",
})<any>(({ theme, isPrimary }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1rem",
	fontWeight: 400,
	color: isPrimary ? theme.palette.primary.main : theme.palette.text.primary,
})) as any;

const OptionSubtext = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: theme.palette.text.secondary,
})) as typeof Typography;

// const SearchIconContainer = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	alignItems: "center",
// 	justifyContent: "center",
// 	width: "2.5rem",
// 	height: "2.35rem",
// 	background: theme.palette.background.paper,
// 	borderRadius: "0.5rem",
// }));

const librariesToLoad: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
	"places",
	"drawing",
	"geometry",
];

const LocationAutoCompleteMobile = ({
	search_id,
	dispatch,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"]["location"];
}) => {
	const [loading, setLoading] = React.useState(false);

	const theme = useTheme();
	const router = useRouter();

	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	/*

  & let's declare the states for the usePlacesAutocomplete hook

  */
	const {
		value,
		suggestions: { data },
		setValue,
		init,
	} = usePlacesAutocomplete({
		initOnMount: false,
		debounce: 500,
	});
	/*

  & let's declare the states for the useLoadScript hook

  */
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || "",
		libraries: librariesToLoad as any,
	});
	const [options, setOptions] = React.useState<string[]>([]);
	const [locationTriggerKey, setLocationTriggerKey] = React.useState(0);
	const getCurrentLocation = () => {
		setValue("");
		/*
    & Next, let's check if the user has allowed us to use their location and update the corresponding state accordingly.
    */
		if (!navigator.geolocation) {
			return;
		} else {
			/*
      & Next, since it looks like the user has allowed us to use their location, let's get their current location. Let's begin by disabling the location autocomplete input.
      */
			// setAllowUserInputInLocationAutocomplete(true);
			navigator.geolocation.getCurrentPosition(
				async (position: GeolocationPosition) => {
					/*
          & Next, let's extract the latitude and longitude from the position object.
          */
					const { latitude, longitude }: { latitude: number; longitude: number } = position.coords;
					/*
          & Next, let's use Google Maps' Reverse Geocoding API to get the user's current address.
          */
					const googleMapsReverseGeocodingApiResponse: GoogleMapsReverseGeocodingApiResponse = await (
						await fetch(
							"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
								latitude +
								"," +
								longitude +
								"&key=" +
								(process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || ""),
							{ cache: "no-store" },
						)
					).json();

					/*
          & Next, let's extract the user's current address from the API response object.
          */
					const userCurrentAddress: string | undefined =
						googleMapsReverseGeocodingApiResponse?.results[0]?.address_components?.find(
							(address_component: GMRGCResultsAddressComponent) =>
								address_component.types.includes("sublocality_level_1") ||
								address_component.types.includes("sublocality_level_2") ||
								address_component.types.includes("sublocality_level_3"),
						)?.long_name;
					const userCurrentAreas: Array<string> | undefined = googleMapsReverseGeocodingApiResponse?.results.map(
						(result) => {
							return result.address_components?.find(
								(address_component: GMRGCResultsAddressComponent) =>
									address_component.types.includes("sublocality_level_1") ||
									address_component.types.includes("postal_town") ||
									address_component.types.includes("locality"),
							)?.long_name as string;
						},
					);

					let userCurrentArea: string | undefined;

					let resultPos: number = 0;

					for (let i = 0; i < userCurrentAreas.length; i++) {
						if (userCurrentAreas[i] !== "Bengaluru") {
							userCurrentArea = userCurrentAreas[i];
							resultPos = i;
							break;
						}
					}

					/*
          & Next, let's update the corresponding state with the user's current address. Let's also set the userLocationAllowed state to true and the allowUserInputInLocationAutocomplete state to false.
          */
					const label = userCurrentAddress ? userCurrentAddress : userCurrentArea ? userCurrentArea : "";
					setValue(label);

					router
						.push(
							{
								pathname: "/search",
							},
							"/search",
							{ locale: router.locale },
						)
						.then(() => {
							dispatch(
								updateSearchFilterSelectedOptionsThunk({
									searchFilterId: search_id,
									newOptions: [
										{
											id: "location",
											title: "Location",
											value: {
												title: label,
												coordinates: [longitude, latitude],
											},
										},
									],
								}),
							);
							dispatch(
								updateSearchFilterAppliedThunk({
									searchFilterId: search_id,
									isApplied: true,
								}),
							);
							dispatch(
								setSearchAnimationDetailsThunk({
									StartAnimate: true,
									TriggerSearch: true,
									ResetSearch: true,
									PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
									HomeAccordion: false,
									OpenLocationDrawer: false,
								}),
							);
						});
					setLoading(false);
				},
				async (error) => {},
				{ timeout: 10000, enableHighAccuracy: true },
			);
		}
	};

	/*

  & let's define the function to handle the change in the location autocomplete

  */
	const onChangeLocationAutocomplete = async (event: React.SyntheticEvent<HTMLSelectElement>, tvalue: any) => {
		if (tvalue === "Use current location") {
			setValue("");
			setLocationTriggerKey((prev) => prev + 1);
			getCurrentLocation();
		} else {
			const item = data.find((res) => res.place_id === tvalue);
			if (item) {
				const temp = item.structured_formatting.main_text;

				if (Number.isNaN(+temp)) {
					// setFromList(temp);
					setValue(temp);
				} else {
				}
				// setFromList(null);
			}
			const { lat, lng } = getLatLng((await getGeocode({ placeId: tvalue }))[0]);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: search_id,
					newOptions: [
						{
							id: "location",
							title: "Location",
							value: {
								title: item?.structured_formatting?.main_text || "",
								coordinates: [lng, lat],
							},
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: true,
				}),
			);
			if (router.asPath.includes("/search")) {
				router
					.push(
						{
							pathname: "/search",
						},
						"/search",
						{ locale: router.locale },
					)
					.then(() => {
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: true,
								PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
								HomeAccordion: false,
								OpenLocationDrawer: false,
							}),
						);
					});
			} else {
				router
					.push(
						{
							pathname: "/search",
						},
						"/search",
						{ locale: router.locale, shallow: true },
					)
					.then(() => {
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
								HomeAccordion: false,
								OpenLocationDrawer: false,
							}),
						);
					});
			}
		}
	};

	/*

  & let's use the useEffect hook to set the value of the location autocomplete to the default location and initialize the autocomplete

  */

	React.useEffect(() => {
		if (isLoaded) {
			init();
		}
	}, [isLoaded, init]);

	React.useEffect(() => {
		const mapped = ["Use current location", ...data.map((suggestion: Suggestion) => suggestion?.place_id)].filter(
			(option: string, position: number, self: Array<string>) =>
				option !== "" && self.findIndex((item: string) => item === option) === position,
		);
		setOptions(mapped);
	}, [data]);

	React.useEffect(() => {
		if (isMobile && search_filter_state?.selected_options.length !== 0) {
			const selectedOption = search_filter_state?.selected_options[0]?.value;
			if (typeof selectedOption === "object" && "title" in selectedOption) {
				setValue(selectedOption.title);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, isMobile]);

	return (
		<React.Fragment>
			<Container
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Autocomplete
					key={locationTriggerKey}
					disablePortal
					freeSolo={true}
					id="combo-box-demo"
					filterOptions={() => options}
					options={options}
					isOptionEqualToValue={(option, value) => {
						const item = data.find((res) => res.structured_formatting.main_text === value)?.place_id;

						return !item ? false : option === item;
					}}
					inputValue={value === "Use current location" ? "" : value}
					renderOption={({ key, ...props }, option) => {
						const las = data.find((suggestion) => suggestion.place_id === option);

						const optionText =
							option === "Use current location" ? "Use current location" : las?.structured_formatting?.main_text;

						const optionSubtext =
							option === "Use current location"
								? "Use current location"
								: las
									? las?.structured_formatting?.secondary_text
									: undefined;

						if (value === optionText) {
							return null;
						}

						return (
							<OptionContainer
								key={key}
								{...props}
							>
								<OptionText
									variant="body1"
									component="span"
									isPrimary={option === "Use current location"}
								>
									{optionText === "Use current location" && (
										<NearMeIcon
											fontSize={"small"}
											sx={{ marginRight: "0.5rem" }}
										/>
									)}
									{optionText === "Use current location" ? "Use current location" : optionText}
								</OptionText>
								{optionSubtext && optionSubtext === "Use current location" ? null : (
									<OptionSubtext
										variant="body2"
										component="span"
										sx={{ display: "flex" }}
									>
										{optionSubtext}
									</OptionSubtext>
								)}
							</OptionContainer>
						);
					}}
					onChange={onChangeLocationAutocomplete}
					sx={{
						width: "100%",
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
						paddingRight: "0px",
						"& .MuiAutocomplete-listbox": {
							padding: 0,
						},
						"&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
							paddingRight: "0px",
						},
						"&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot": {
							paddingRight: "0px",
						},
					}}
					renderInput={(params) => (
						<TextField
							placeholder={
								router.locale === "ar-AE"
									? "أدخل موقعًا..."
									: router.locale === "kn-IN"
										? "ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ..."
										: "Enter a location..."
							}
							variant="standard"
							{...params}
							onChange={(e) => {
								console.log(e.target.value, "evalue");
								setValue(e.target.value);
							}}
							sx={{
								fontSize: "0.875rem",
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment position="end">
										{!loading ? (
											<Search
												sx={{
													cursor: "pointer",
													color: theme.palette.text.secondary,
												}}
											/>
										) : (
											<CircularProgress
												size={24}
												sx={{ color: theme.palette.text.secondary }}
											/>
										)}
									</InputAdornment>
								),
								disableUnderline: true,
							}}
						/>
					)}
				/>
			</Container>
		</React.Fragment>
	);
};

export default LocationAutoCompleteMobile;
